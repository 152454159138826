var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('auth-container',{scopedSlots:_vm._u([{key:"authForm",fn:function(){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onVerify($event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onVerify()}}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('label',{staticClass:"label has-text-left"},[_vm._v(_vm._s(_vm.$t('user-admin-email')))]),_c('b-field',[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|emailValidator"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"id":"email","type":"email","icon":"at","autocomplete":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('span',{staticClass:"help is-danger has-text-weight-bold",staticStyle:{"min-height":"1rem"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('label',{staticClass:"label has-text-left"},[_vm._v(_vm._s(_vm.$t('user-admin-verification-code')))]),_c('b-field',[_c('validation-provider',{attrs:{"rules":"length:6|required","name":"verificationCode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"id":"verification-code","type":"numeric"},model:{value:(_vm.verificationCode),callback:function ($$v) {_vm.verificationCode=$$v},expression:"verificationCode"}}),_c('span',{staticClass:"help is-danger has-text-weight-bold",staticStyle:{"min-height":"1rem"}},[_vm._v(_vm._s(errors[0]))]),_c('b-button',{class:{ 'is-loading': _vm.isLoading },attrs:{"disabled":!valid,"type":"is-info"},on:{"click":_vm.onVerify}},[_vm._v(" "+_vm._s(_vm.$t('user-admin-verify-code'))+" ")])]}}],null,true)})],1),(_vm.showLoginLink)?_c('router-link',{staticClass:"mb-1",attrs:{"to":"signin"}},[_vm._v(_vm._s(_vm.$t('user-admin-verified-go-to-login'))+" ")]):_c('div',{staticClass:"is-centered"},[_c('b-button',{attrs:{"type":"is-link"}},[_vm._v(_vm._s(_vm.$t('user-admin-resend-verification-code')))])],1),_c('br')]}}])})],1),(_vm.verifyError)?_c('div',[_c('span',{class:{
          help: true,
          'is-danger': true,
          'has-text-weight-bold': true,
        }},[_vm._v(" "+_vm._s(_vm.verifyError)+" ")])]):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }