<!--
  Component for logging into the portal
-->
<template>
  <auth-container>
    <template #authForm>
      <form @submit.prevent="onVerify" @keyup.enter="onVerify()">
        <validation-observer v-slot="{ valid }">
          <label class="label has-text-left">{{
            $t('user-admin-email')
          }}</label>
          <b-field>
            <ValidationProvider
              v-slot="{ errors }"
              name="Email"
              rules="required|emailValidator"
            >
              <b-input
                id="email"
                v-model="email"
                type="email"
                icon="at"
                autocomplete="email"
              ></b-input>
              <span
                style="min-height: 1rem"
                class="help is-danger has-text-weight-bold"
                >{{ errors[0] }}</span
              >
            </ValidationProvider>
          </b-field>
          <label class="label has-text-left">{{
            $t('user-admin-verification-code')
          }}</label>
          <b-field>
            <validation-provider
              v-slot="{ errors }"
              rules="length:6|required"
              name="verificationCode"
            >
              <b-input
                id="verification-code"
                v-model="verificationCode"
                type="numeric"
              ></b-input>
              <span
                style="min-height: 1rem"
                class="help is-danger has-text-weight-bold"
                >{{ errors[0] }}</span
              >
              <b-button
                :disabled="!valid"
                type="is-info"
                :class="{ 'is-loading': isLoading }"
                @click="onVerify"
              >
                {{ $t('user-admin-verify-code') }}
              </b-button>
            </validation-provider>
          </b-field>
          <router-link v-if="showLoginLink" to="signin" class="mb-1"
            >{{ $t('user-admin-verified-go-to-login') }}
          </router-link>
          <div v-else class="is-centered">
            <b-button type="is-link">{{
              $t('user-admin-resend-verification-code')
            }}</b-button>
          </div>
          <br />
        </validation-observer>
      </form>
      <div v-if="verifyError">
        <span
          :class="{
            help: true,
            'is-danger': true,
            'has-text-weight-bold': true,
          }"
        >
          {{ verifyError }}
        </span>
      </div>
    </template>
  </auth-container>
</template>

<script>
import { mapActions } from 'vuex';
import { defineComponent } from '@vue/composition-api';
import AuthContainer from './AuthContainer';
import { length, required } from 'vee-validate/dist/rules';

import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend,
} from 'vee-validate';

export default defineComponent({
  components: {
    AuthContainer,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    providedEmail: {
      required: false,
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
      email: undefined,
      verifyError: undefined,
      verificationCode: undefined,
      showLoginLink: false,
    };
  },
  created() {
    this.email = this.providedEmail;
    extend('emailValidator', {
      message: (field) => {
        return this.$t('Email address not from appropriate domain');
      },
      validate: (value) => {
        const re = new RegExp(this.emailDomainRegex);
        return re.test(value);
      },
    });
    extend('length', length);
    extend('required', required);
  },
  mounted() {
    this.email = this.providedEmail;
  },
  methods: {
    ...mapActions('storeAuth', ['verifyUser', 'resendVerificationCode']),
    navigateToSignIn() {
      this.$router.replace({
        name: 'signin',
      });
    },
    async onVerify() {
      try {
        this.showLoginLink = false;
        this.isLoading = true;
        this.verifyError = null;
        await this.verifyUser({
          username: this.email,
          code: this.verificationCode,
        });
        this.$buefy.toast.open({
          duration: 3000,
          message: `Successfully validated email ${this.email}`,
          type: 'is-success',
        });
        this.showLoginLink = true;
      } catch (err) {
        this.verifyError = err.message;
      } finally {
        this.isLoading = false;
      }
    },
    async onResendVerification() {
      try {
        this.isLoading = true;
        this.verifyError = null;
        await this.resendVerificationCode({
          username: this.email,
        });
      } catch (err) {
        this.verifyError = err.message;
      } finally {
        this.isLoading = false;
      }
    },
  },
});
</script>
